import React from 'react';

function DateLogo() {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M28 36H8C3.58 36 0 32.42 0 28V8C0 3.58 3.58 0 8 0H28C32.42 0 36 3.58 36 8V28C36 32.42 32.42 36 28 36Z'
        fill='#454545'
      />
      <path
        d='M25 10H23V9C23 8.73478 22.8946 8.48043 22.7071 8.29289C22.5196 8.10536 22.2652 8 22 8C21.7348 8 21.4804 8.10536 21.2929 8.29289C21.1054 8.48043 21 8.73478 21 9V10H15V9C15 8.73478 14.8946 8.48043 14.7071 8.29289C14.5196 8.10536 14.2652 8 14 8C13.7348 8 13.4804 8.10536 13.2929 8.29289C13.1054 8.48043 13 8.73478 13 9V10H11C10.2044 10 9.44129 10.3161 8.87868 10.8787C8.31607 11.4413 8 12.2044 8 13V25C8 25.7956 8.31607 26.5587 8.87868 27.1213C9.44129 27.6839 10.2044 28 11 28H25C25.7956 28 26.5587 27.6839 27.1213 27.1213C27.6839 26.5587 28 25.7956 28 25V13C28 12.2044 27.6839 11.4413 27.1213 10.8787C26.5587 10.3161 25.7956 10 25 10ZM26 25C26 25.2652 25.8946 25.5196 25.7071 25.7071C25.5196 25.8946 25.2652 26 25 26H11C10.7348 26 10.4804 25.8946 10.2929 25.7071C10.1054 25.5196 10 25.2652 10 25V18H26V25ZM26 16H10V13C10 12.7348 10.1054 12.4804 10.2929 12.2929C10.4804 12.1054 10.7348 12 11 12H13V13C13 13.2652 13.1054 13.5196 13.2929 13.7071C13.4804 13.8946 13.7348 14 14 14C14.2652 14 14.5196 13.8946 14.7071 13.7071C14.8946 13.5196 15 13.2652 15 13V12H21V13C21 13.2652 21.1054 13.5196 21.2929 13.7071C21.4804 13.8946 21.7348 14 22 14C22.2652 14 22.5196 13.8946 22.7071 13.7071C22.8946 13.5196 23 13.2652 23 13V12H25C25.2652 12 25.5196 12.1054 25.7071 12.2929C25.8946 12.4804 26 12.7348 26 13V16Z'
        fill='#FFCD25'
        fillOpacity='0.79'
      />
    </svg>
  );
}

export default DateLogo;
